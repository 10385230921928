<template>
    <v-col cols="12">
        <v-card outlined height="auto" min-width="70%" class="py-8" :style="{borderColor: parameters.secondaryColor}" elevation="2">
            <h2 class="font-weight-light">
                Seu carrinho está vazio
            </h2>
            <v-subheader class="justify-center subtitle-2">Conheça nossas coleções e adicione produtos.</v-subheader>
            <v-card-actions class="mt-4 justify-center">
                <v-btn outlined :color="parameters.secondaryColor" @click="$router.push('/home')">Ir às compras</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
};
</script>

<style>
</style>