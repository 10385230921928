<template>
    <v-col :cols="12" md="9" sm="12" class="pb-10">
        <v-card
            v-for="(saleLine, i) in salesLines"
            :key="i"
            class="mx-auto my-1"
            max-width="600"
            height="auto"
        >
            <v-list-item three-line>
                <v-list-item-avatar
                    tile
                    height="100%"
                    width="80"
                    color="transparent"
                >
                    <v-img :src="saleLine.vd3foto"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="text-left">
                    <v-list-item-title class="mb-1" style="white-space: break-spaces"><a class="subtitle-2" @click="openProduct(saleLine.vd2produto)">{{ saleLine.vd3desc | capitalize }}</a></v-list-item-title>
                    <v-list-item-subtitle class="mb-1">{{ `${saleLine.vd2produto}-${saleLine.vd3opcao}-${saleLine.vd3tamanho}`}}</v-list-item-subtitle>
                    <v-list-item-subtitle class="caption d-flex flex-row align-center">
                        <span>Cor </span>
                        <v-card
                            color="white"
                            class="d-flex justify-center align-center mx-1 my-1"
                            elevation="0"
                            style="border: #dcdcdc 1px solid !important"
                            dark
                            max-width="25"
                            height="25"
                            width="25"
                        >
                            <v-img 
                                v-if="saleLine.cr1imagem"
                                :src="saleLine.cr1imagem"
                                aspect-ratio="1"
                                contain
                                v-on:error="onImgError(saleLine)"
                            />
                            <v-card
                                v-else
                                dark
                                :color="saleLine.hexadecimal"
                                height="19"
                                width="19"
                                elevation="0"
                            >
                            </v-card>
                        </v-card>
                        <span>| Tamanho</span>
                        <v-card
                            color="white"
                            class="d-flex justify-center align-center mx-1 my-1"
                            elevation="0"
                            style="border: #dcdcdc 1px solid !important"
                            dark
                            max-width="25"
                            height="25"
                            width="25"
                        >
                            <v-card
                                dark
                                color="transparent"
                                class="d-flex align-center"
                                height="19"
                                width="19"
                                elevation="0"
                            >
                                <div class="caption flex-grow-1 text-center" :style="{color: parameters.primaryColor}">{{ saleLine.vd3tamanho }}</div>
                            </v-card>
                        </v-card>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle >
                        <input v-model.number="saleLine.vd3qtde" type="number" class="vd3qtde ml-0" :min="1" @change="change" @input="setQtde(saleLine)"/>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon small @click="removeProduct(i)">
                        <v-icon color="red" small>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-card-actions class="justify-end align-end font-weight-bold px-4" :style="{color: parameters.primaryColor}">{{ saleLine.vd5preco | money}}</v-card-actions>
        </v-card>
    </v-col>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        salesLines: {
            get() {
                return this.$store.state.cart.salesLines;
            },
        },
    },
    methods:{
        openProduct(vd2produto){
            this.$router.push(`/produto/${vd2produto}`)
        },
        removeProduct(position){
            this.$store.dispatch("cart/remove", position)
        },
        change(){
            this.$store.dispatch("cart/save")
        },
        onImgError(saleLine){
            saleLine.cr1imagem = ""
        },
        setQtde(saleLine){
            if (!(/^\d+$/.test(saleLine.vd3qtde)))
                saleLine.vd3qtde = 1
            let qtyInCart = saleLine.vd3qtde ? saleLine.vd3qtde : 1

            if (this.parameters.store.b2bpronta && saleLine.vd3estoque > 0 && qtyInCart > saleLine.vd3estoque) {
                saleLine.vd3qtde = saleLine.vd3estoque
            }
            if (qtyInCart < 0)
                saleLine.vd3qtde = 1
        }
    }
};
</script>

<style>
</style>