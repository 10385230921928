<template>
    <v-col
        cols="12"
        md="3"
        sm="12"
        style="background-color: lightgray"
        class="d-flex flex-column"
    >
        <p class="headline">Resumo da compra</p>
        <p class="overline">
            O valor de frete será negociado durante a emissão da nota fiscal
        </p>
        <div class="flex-grow-1"></div>
        <v-simple-table>
            <template v-slot:default>
                <tbody>
                    <tr>
                        <td class="text-left">Total</td>
                        <td class="text-right" style="width: 50px" :style="{color: parameters.primaryColor}">
                            <b>{{ totalCart | money }}</b>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div class="text-center">
            <v-btn
                class="mt-5 white--text"
                tile
                block
                :color="parameters.secondaryColor"
                :elevation="0"
                @click="finalizeOrder"
                >COMPRAR</v-btn
            >
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        ...mapGetters({
            totalCart: 'cart/totalCart',
        })
    },
    methods:{
        finalizeOrder(){
            this.$router.push("/resumo")
        }
    }
};
</script>

<style>
</style>